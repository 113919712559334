<template>
  <div class="messageInfo" v-if="info">
    <p class="newsTitle">{{ info.Title }}</p>
    <p class="newsPreview">{{ info.MessageContent }}</p>
    <p class="newsBottom">{{ info.ReleaseTime }}</p>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "MessageInfo",
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      userId: "",
      info: {},
      type: "B",
    };
  },
  mounted() {
    this.userId = JSON.parse(localStorage.getItem("auth")).userId;
    this.getMessageInfo();
  },
  methods: {
    getMessageInfo() {
      this.$commonMethod.showLoading();
      let param = {
        userId: this.userId,
        MessageId: this.id,
        ReceipType: this.type,
      };
      this.$api.system
        .messageInfo(param)
        .then((res) => {
          const data = res.data;
          this.info = data;
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.messageInfo {
  background: #fff;
  padding: 5%;
  border-bottom: 1px solid #e5e5e5;
  .newsTitle {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }
  .newsPreview {
    margin-top: 1rem;
    margin-bottom: 0rem;
    font-size: 12px;
    color: #999;
  }
  .newsBottom {
    margin-top: 1rem;
    margin-bottom: 0rem;
    font-size: 12px;
    color: #999;
    text-align: right;
  }
}
</style>